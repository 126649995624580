import {createAction} from "redux-api-middleware";
import {API_URL} from '../constants';

import {withAuth} from "../reducers";
import {
    ORGANISATION_RESET, ORGANISATION_COACHES_RESET, ORGANISATION_GROUPS_RESET,
    ORGANISATIONS_GET_RSAA,
    COACH_POST_RSAA,
    COACHES_GET_RSAA,
    COACHES_ORGANISATIONS_GET_REQUEST, COACHES_ORGANISATIONS_GET_SUCCESS, COACHES_ORGANISATIONS_GET_FAILURE,
    GROUPS_ORGANISATIONS_REQUEST, GROUPS_ORGANISATIONS_GET_SUCCESS, GROUPS_ORGANISATIONS_GET_FAILURE,
    GROUP_POST_RSAA,
    GROUPS_GET_RSAA,
    STUDENT_GET_RSAA,
    STUDENT_ADDINFO_GET_RSAA,
    STUDENTS_PROGRESS_GET_RSAA,
    STUDENTS_SEARCH_GET_RSAA,
    VOUCHERS_GET_RSAA,
    STUDENT_ANSWERS_GET_RSAA,
    STUDENT_DELETE_ANSWER_POST_RSAA,
    STUDENT_DOWNLOAD_ANSWER_GET_RSAA, STUDENT_DOWNLOAD_ANSWER_RESET
} from "../constants/organisation";
import {updateProfilePicture} from "./auth";

export const resetOrganisation = () => ({
    type: ORGANISATION_RESET,
})

export const resetOrganisationCoaches = () => ({
    type: ORGANISATION_COACHES_RESET,
})

export const resetOrganisationGroups = () => ({
    type: ORGANISATION_GROUPS_RESET,
})

export const fetchOrganisations = () => createAction({
    endpoint: API_URL + 'organisations/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: ORGANISATIONS_GET_RSAA
});

/**
 * COACHES
 */
export const postCoachAPI = (id, data) => createAction({
    endpoint: API_URL + 'organisations/' + id + '/coaches/',
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: COACH_POST_RSAA,

})

export function postCoach(id, data, organisations) {
    return function(dispatch, getState) {
        return dispatch(postCoachAPI(id, data))
            .then(() => dispatch(fetchCoachesByOrganisations(organisations)));
    };
}

export function fetchCoachesByOrganisations(organisations) {

    return function(dispatch, getState) {
        dispatch(resetOrganisationCoaches())

        for (let i = 0; i < organisations.length; i++) {
            dispatch(fetchCoaches(organisations[i].id, true, organisations[i].name))
        }

        return true
    }
}

export const fetchCoaches = (id, multipleOrganisations = false, name = undefined) => createAction({
    endpoint: API_URL + 'organisations/' + id + '/coaches/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: multipleOrganisations ? [
        COACHES_ORGANISATIONS_GET_REQUEST,
        {
            type: COACHES_ORGANISATIONS_GET_SUCCESS,
            payload: (action, state, res) => {
                return res.json().then((json) => { return { organisation: { id: id, name: name }, ...json } });
            }
        },
        COACHES_ORGANISATIONS_GET_FAILURE,
    ] : COACHES_GET_RSAA,
});


/**
 * GROUPS
 */

export const postGroupAPI = (id, data) => createAction({
    endpoint: API_URL + 'organisations/' + id + '/groups/',
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: GROUP_POST_RSAA,
})

export function postGroup(id, data, organisations) {
    return function(dispatch, getState) {
        return dispatch(postGroupAPI(id, data))
            .then(() => dispatch(fetchGroupsByOrganisations(organisations)));
    };
}

export function fetchGroupsByOrganisations(organisations) {

    return function(dispatch, getState) {
        dispatch(resetOrganisationGroups())

        for (let i = 0; i < organisations.length; i++) {
            dispatch(fetchGroups(organisations[i].id, true, organisations[i].name))
        }

        return true
    }
}

export const fetchGroups = (id, multipleOrganisations = false, name = undefined) => createAction({
    endpoint: API_URL + 'organisations/' + id + '/groups/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: multipleOrganisations ? [
        GROUPS_ORGANISATIONS_REQUEST,
        {
            type: GROUPS_ORGANISATIONS_GET_SUCCESS,
            payload: (action, state, res) => {
                return res.json().then((json) => { return { organisation: { id: id, name: name }, ...json } });
            }
        },
        GROUPS_ORGANISATIONS_GET_FAILURE,
    ] : GROUPS_GET_RSAA,
});


/**
 * Profile
 */

export const postProfilePictureAPI = (formData) => createAction({
    endpoint: API_URL + 'students/add_info/',
    method: 'PATCH',
    body: formData,
    credentials: 'omit',
    headers: withAuth({}),
    types: STUDENT_ADDINFO_GET_RSAA
})

export function postProfilePicture(id, data) {
    return function(dispatch, getState) {

        let formData = new FormData();
        formData.append('profile_picture', data.profile_picture);

        return dispatch(postProfilePictureAPI(formData))
            .then((response) => {

                if ('profile_picture' in response.payload) {
                    return dispatch(updateProfilePicture(response.payload.profile_picture))
                }

                return false;
            });
    };
}

/**
 * STUDENT
 */

export const fetchStudent = (id) => createAction({
    endpoint: API_URL + 'students/' + id  + '/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENT_GET_RSAA
});

export const fetchStudentAnswers = (id, course_id) => createAction({
    endpoint: API_URL + 'pages/' + course_id + '/students/answers/' + id + '/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENT_ANSWERS_GET_RSAA
});


export function fetchStudentDownloadAnswers(id, course_id) {
    return function(dispatch, getState) {

        return dispatch(fetchStudentDownloadAnswersAPI(id, course_id))
            .then((response) => {
                return dispatch(studentDownloadAnswersReset())
            });
    };
}

export const fetchStudentDownloadAnswersAPI = (id, course_id) => createAction({
    endpoint: API_URL + 'students/' + id.toString() + '/answers/download/'+ course_id.toString() +'/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENT_DOWNLOAD_ANSWER_GET_RSAA
});

export const studentDownloadAnswersReset = () => ({
    type: STUDENT_DOWNLOAD_ANSWER_RESET,
})

export const postStudentDeleteAnswers = (data) => createAction({
    endpoint: API_URL + 'students/answers/delete/',
    method: 'POST',
    credentials: 'omit',
    body: JSON.stringify(data),
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENT_DELETE_ANSWER_POST_RSAA
});

export const postStudentInfoAPI = (data) => createAction({
    endpoint: API_URL + 'students/add_info/',
    method: 'PATCH',
    body: JSON.stringify(data),
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENT_ADDINFO_GET_RSAA
})

export function postStudentInfo(id, data) {
    return function(dispatch, getState) {
        return dispatch(postStudentInfoAPI(data))
            .then(() => dispatch(fetchStudent(id)));
    };
}

export const fetchSearchStudents = (searchParams) => createAction({
    endpoint: API_URL + 'students/search/' + searchParams,
    method: 'GET',
    credentials: 'omit',
    headers: withAuth(),
    types: STUDENTS_SEARCH_GET_RSAA
});

/**
 * STUDENTS - PROGRESS
 */

export const fetchStudents = (id) => createAction({
    endpoint: API_URL + 'students/progress/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: STUDENTS_PROGRESS_GET_RSAA
});


/**
 * VOUCHERS
 */

export const fetchVouchers = (id) => createAction({
    endpoint: API_URL + 'students/vouchers/',
    method: 'GET',
    credentials: 'omit',
    headers: withAuth({ 'Content-Type': 'application/json' }),
    types: VOUCHERS_GET_RSAA
});