export const PAGES_GET_REQUEST = '@@page/PAGES_GET_REQUEST';
export const PAGES_GET_SUCCESS = '@@page/PAGES_GET_SUCCESS';
export const PAGES_GET_FAILURE = '@@page/PAGES_GET_FAILURE';
export const PAGES_GET_RSAA = [PAGES_GET_REQUEST, PAGES_GET_SUCCESS, PAGES_GET_FAILURE];

export const PAGE_GET_REQUEST = '@@page/PAGE_GET_REQUEST';
export const PAGE_GET_SUCCESS = '@@page/PAGE_GET_SUCCESS';
export const PAGE_GET_FAILURE = '@@page/PAGE_GET_FAILURE';
export const PAGE_GET_RSAA = [PAGE_GET_REQUEST, PAGE_GET_SUCCESS, PAGE_GET_FAILURE];

export const MENU_RESET = '@@page/MENU_RESET';
export const MENU_GET_REQUEST = '@@page/MENU_GET_REQUEST';
export const MENU_GET_SUCCESS = '@@page/MENU_GET_SUCCESS';
export const MENU_GET_FAILURE = '@@page/MENU_GET_FAILURE';
export const MENU_GET_RSAA = [MENU_GET_REQUEST, MENU_GET_SUCCESS, MENU_GET_FAILURE];

export const QUESTION_GET_REQUEST = '@@page/QUESTION_GET_REQUEST';
export const QUESTION_GET_SUCCESS = '@@page/QUESTION_GET_SUCCESS';
export const QUESTION_GET_FAILURE = '@@page/QUESTION_GET_FAILURE';
export const QUESTION_GET_RSAA = [QUESTION_GET_REQUEST, QUESTION_GET_SUCCESS, QUESTION_GET_FAILURE];

export const STUDENTS_ANSWER_GET_REQUEST = '@@page/STUDENTS_ANSWER_GET_REQUEST';
export const STUDENTS_ANSWER_GET_SUCCESS = '@@page/STUDENTS_ANSWER_GET_SUCCESS';
export const STUDENTS_ANSWER_GET_FAILURE = '@@page/STUDENTS_ANSWER_GET_FAILURE';
export const STUDENTS_ANSWER_GET_RSAA = [STUDENTS_ANSWER_GET_REQUEST, STUDENTS_ANSWER_GET_SUCCESS, STUDENTS_ANSWER_GET_FAILURE];

export const STUDENTS_DELETE_ANSWER_POST_REQUEST = '@@page/STUDENTS_DELETE_ANSWER_POST_REQUEST';
export const STUDENTS_DELETE_ANSWER_POST_SUCCESS = '@@page/STUDENTS_DELETE_ANSWER_POST_SUCCESS';
export const STUDENTS_DELETE_ANSWER_POST_FAILURE = '@@page/STUDENTS_DELETE_ANSWER_POST_FAILURE';
export const STUDENTS_DELETE_ANSWER_POST_RSAA = [STUDENTS_DELETE_ANSWER_POST_REQUEST, STUDENTS_DELETE_ANSWER_POST_SUCCESS, STUDENTS_DELETE_ANSWER_POST_FAILURE];

export const GET_ANSWER_REQUEST = '@@page/GET_ANSWER_REQUEST';
export const GET_ANSWER_SUCCESS = '@@page/GET_ANSWER_SUCCESS';
export const GET_ANSWER_FAILURE = '@@page/GET_ANSWER_FAILURE';
export const GET_ANSWER_RSAA = [GET_ANSWER_REQUEST, GET_ANSWER_SUCCESS, GET_ANSWER_FAILURE];

export const GET_ANSWERS_REQUEST = '@@page/GET_ANSWERS_REQUEST';
export const GET_ANSWERS_SUCCESS = '@@page/GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_FAILURE = '@@page/GET_ANSWERS_FAILURE';
export const GET_ANSWERS_RSAA = [GET_ANSWERS_REQUEST, GET_ANSWERS_SUCCESS, GET_ANSWERS_FAILURE];

export const POST_ANSWER_REQUEST = '@@page/POST_ANSWER_REQUEST';
export const POST_ANSWER_SUCCESS = '@@page/POST_ANSWER_SUCCESS';
export const POST_ANSWER_FAILURE = '@@page/POST_ANSWER_FAILURE';
export const POST_ANSWER_RSAA = [POST_ANSWER_REQUEST, POST_ANSWER_SUCCESS, POST_ANSWER_FAILURE];

export const POST_ANSWERS_REQUEST = '@@page/POST_ANSWERS_REQUEST';
export const POST_ANSWERS_SUCCESS = '@@page/POST_ANSWERS_SUCCESS';
export const POST_ANSWERS_FAILURE = '@@page/POST_ANSWERS_FAILURE';
export const POST_ANSWERS_RSAA = [POST_ANSWERS_REQUEST, POST_ANSWERS_SUCCESS, POST_ANSWERS_FAILURE];

export const SUBMIT_EXAM_REQUEST = '@@page/SUBMIT_EXAM_REQUEST';
export const SUBMIT_EXAM_SUCCESS = '@@page/SUBMIT_EXAM_SUCCESS';
export const SUBMIT_EXAM_FAILURE = '@@page/SUBMIT_EXAM_FAILURE';
export const SUBMIT_EXAM_RSAA = [SUBMIT_EXAM_REQUEST, SUBMIT_EXAM_SUCCESS, SUBMIT_EXAM_FAILURE];

export const GET_EXAM_REQUEST = '@@page/GET_EXAM_REQUEST';
export const GET_EXAM_SUCCESS = '@@page/GET_EXAM_SUCCESS';
export const GET_EXAM_FAILURE = '@@page/GET_EXAM_FAILURE';
export const GET_EXAM_RSAA = [GET_EXAM_REQUEST, GET_EXAM_SUCCESS, GET_EXAM_FAILURE];

export const SIDE_MENU_GET_REQUEST = '@@page/SIDE_MENU_GET_REQUEST';
export const SIDE_MENU_GET_SUCCESS = '@@page/SIDE_MENU_GET_SUCCESS';
export const SIDE_MENU_GET_FAILURE = '@@page/SIDE_MENU_GET_FAILURE';
export const SIDE_MENU_GET_RSAA = [SIDE_MENU_GET_REQUEST, SIDE_MENU_GET_SUCCESS, SIDE_MENU_GET_FAILURE];

export const SIDE_MENU_BLOCK_GET_REQUEST = '@@page/SIDE_MENU_BLOCK_GET_REQUEST';
export const SIDE_MENU_BLOCK_GET_SUCCESS = '@@page/SIDE_MENU_BLOCK_GET_SUCCESS';
export const SIDE_MENU_BLOCK_GET_FAILURE = '@@page/SIDE_MENU_BLOCK_GET_FAILURE';
export const SIDE_MENU_BLOCK_GET_RSAA = [SIDE_MENU_BLOCK_GET_REQUEST, SIDE_MENU_BLOCK_GET_SUCCESS, SIDE_MENU_BLOCK_GET_FAILURE];

export const RESET_ANSWER = '@@page/RESET_ANSWER';