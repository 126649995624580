import React, {Component} from 'react';
import './static/styles/app.scss';
import Header from './components/Header';
import Content from "./components/Content";
import Footer from "./components/Inc/Footer";
import CookieNotice from "./components/Inc/CookieNotice";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faInfoCircle, faCaretRight, faCaretUp, faCaretDown, faArrowDown, faArrowLeft, faArrowRight, faTimes, faPlus, faFilter, faCheck, faPencilAlt, faComment, faTrashAlt, faDownload, faSpinner} from '@fortawesome/free-solid-svg-icons'
library.add(faSearch, faInfoCircle, faCaretRight, faCaretUp, faCaretDown,faArrowDown, faArrowLeft, faArrowRight, faTimes, faPlus, faFilter, faCheck, faPencilAlt, faComment, faTrashAlt, faDownload, faSpinner);

require('dotenv').config();

class App extends Component {

    render() {
        return (
            <div className="App">
                <Header/>
                <main>
                    <Content/>
                </main>
                <Footer/>
                <CookieNotice />
            </div>
        );
    }
}

export default App;
