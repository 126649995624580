import {
    ORGANISATION_RESET,
    ORGANISATION_COACHES_RESET,
    ORGANISATION_GROUPS_RESET,
    ORGANISATIONS_GET_SUCCESS,
    COACHES_GET_SUCCESS,
    GROUPS_GET_SUCCESS,
    STUDENT_GET_SUCCESS,
    STUDENT_ANSWERS_GET_SUCCESS,
    STUDENT_ANSWERS_GET_FAILURE,
    COACHES_ORGANISATIONS_GET_SUCCESS,
    GROUPS_ORGANISATIONS_GET_SUCCESS,
    STUDENTS_PROGRESS_GET_SUCCESS,
    COACH_POST_REQUEST,
    COACH_POST_FAILURE,
    GROUP_POST_REQUEST,
    GROUP_POST_FAILURE,
    STUDENTS_SEARCH_GET_SUCCESS,
    VOUCHERS_GET_SUCCESS,
    STUDENT_DELETE_ANSWER_POST_FAILURE,
    STUDENT_DELETE_ANSWER_POST_REQUEST,
    STUDENT_DELETE_ANSWER_POST_SUCCESS,
    STUDENT_ANSWERS_GET_REQUEST,
    STUDENT_DOWNLOAD_ANSWER_GET_FAILURE,
    STUDENT_DOWNLOAD_ANSWER_GET_REQUEST,
    STUDENT_DOWNLOAD_ANSWER_GET_SUCCESS,
    STUDENT_DOWNLOAD_ANSWER_RESET
} from "../constants/organisation";

import JsFileDownloader from 'js-file-downloader';
import {STATIC_URL} from "../constants";

const initialState = {
    list: undefined,
    coaches: undefined,
    groups: undefined,
    student: undefined,
    student_answers: undefined,
    student_answers_deleted: undefined,
    student_answers_download: undefined,
    students: undefined,
    progress: undefined,
    vouchers: undefined,
    errors: {},
};

const OrganisationReducer = (state = initialState, action) => {

    let errors;

    switch (action.type) {
        case ORGANISATION_RESET:
            return Object.assign({}, state, {
                list: undefined,
                coaches: undefined,
                student: undefined,
            });
        case ORGANISATION_COACHES_RESET:
            return Object.assign({}, state, {
                coaches: undefined,
            });
        case ORGANISATION_GROUPS_RESET:
            return Object.assign({}, state, {
                groups: undefined,
            });
        case ORGANISATIONS_GET_SUCCESS:
            return Object.assign({}, state, {
                list: action.payload.organisations,
            });
        case COACHES_GET_SUCCESS:
            return Object.assign({}, state, {
                coaches: action.payload.coaches,
            });
        case COACHES_ORGANISATIONS_GET_SUCCESS:

            let coaches = [];
            if (state.coaches !== undefined) {
                coaches = Object.values(state.coaches);
            }
            coaches.push(action.payload)

            return Object.assign({}, state, {
                coaches: coaches,
            });
        case GROUPS_GET_SUCCESS:
            return Object.assign({}, state, {
                groups: action.payload.groups,
            });
        case GROUPS_ORGANISATIONS_GET_SUCCESS:

            let groups = [];
            if (state.groups !== undefined) {
                groups = Object.values(state.groups);
            }
            groups.push(action.payload)

            return Object.assign({}, state, {
                groups: groups,
            });
        case STUDENT_GET_SUCCESS:
            return Object.assign({}, state, {
                student: action.payload,
            });
        case STUDENT_ANSWERS_GET_SUCCESS:
            return Object.assign({}, state, {
                student_answers: action.payload,
            });

        case STUDENT_ANSWERS_GET_REQUEST:
        case STUDENT_ANSWERS_GET_FAILURE:
            return Object.assign({}, state, {
                student_answers: undefined,
            });
        case STUDENTS_PROGRESS_GET_SUCCESS:
            return Object.assign({}, state, {
                progress: action.payload,
            });
        case COACH_POST_REQUEST:
        case GROUP_POST_REQUEST:
            return Object.assign({}, state, {
                errors: {},
            });
        case COACH_POST_FAILURE:

            errors = state.errors;
            if (action.payload.status === 400 && "non_field_errors" in action.payload.response) {
                errors['coach_post'] = action.payload.response.non_field_errors;
            } else {
                errors['coach_post'] = 'Er was een probleem met het opslaan van de opleider.'
            }

            return Object.assign({}, state, {
                errors: errors,
            });
        case GROUP_POST_FAILURE:

            errors = state.errors;
            if (action.payload.status === 400 && "non_field_errors" in action.payload.response) {
                errors['group_post'] = action.payload.response.non_field_errors;
            } else {
                errors['group_post'] = 'Er was een probleem met het opslaan van de groep.' + action.payload.non_field_errors
            }

            return Object.assign({}, state, {
                errors: errors,
            });
        case STUDENTS_SEARCH_GET_SUCCESS:
            return {
                students: action.payload.results,
            };
        case VOUCHERS_GET_SUCCESS:
            return Object.assign({}, state, {
                vouchers: action.payload,
            });

        case STUDENT_DELETE_ANSWER_POST_REQUEST:
            return Object.assign({}, state, {
                student_answers_deleted: undefined
            });

        case STUDENT_DELETE_ANSWER_POST_SUCCESS:
            return Object.assign({}, state, {
                student_answers: undefined,
                student_answers_deleted: action.payload
            });

        case STUDENT_DELETE_ANSWER_POST_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });

        case STUDENT_DOWNLOAD_ANSWER_RESET:
        case STUDENT_DOWNLOAD_ANSWER_GET_REQUEST:
            return Object.assign({}, state, {
                student_answers_download: undefined,
            });

        case STUDENT_DOWNLOAD_ANSWER_GET_SUCCESS:

            let errorMessage = false;

            if( 'url' in action.payload && action.payload.url !== '' ) {

                new JsFileDownloader({
                    url: STATIC_URL + action.payload.url
                })
                .then(function () {
                    // Called when download ended
                    return Object.assign({}, state, {
                        student_answers_download: true,
                    });
                })
                .catch(function (error) {
                    // Called when an error occurred
                    console.log('Tectum: Error while downloading file', error)
                    errorMessage = error
                });

            }

            return Object.assign({}, state, {
                student_answers_download: errorMessage,
            });

        case STUDENT_DOWNLOAD_ANSWER_GET_FAILURE:
            return Object.assign({}, state, {
                errors: action.payload
            });

        default:
            return state
    }
}

export default OrganisationReducer;